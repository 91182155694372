/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */
const { errorMessages } = require("../error-messages/error-messages");
import { validate as uuidValidate } from "uuid";

const language = process.env.VUE_APP_LANGUAGE || "en";
const em = errorMessages[language];

export const customValidationRuleDefinitions = (em = em) => [
  /**
   * EXAMPLES:
   *
   * Below you find 2 examples on how custom validation rule definitions
   * should be defined:  ...
   */

  /**
   * You could use an argument (validationArgumentKey) to confirm
   * if validation must be used, e.g.
   *    validation: {
   *      validEvToolsProductOwner: false
   *    }
   *  will cancel validation (return true)
   */

  {
    validationKey: "validEvToolsProductOwner",
    validationArgumentKey: "validEvToolsProductOwner",
    ruleFunction: (argument) => (value) => {
      if (!value || argument === false) {
        return true;
      }
      return String(value).includes("Jochem")
        ? true
        : `this is not a valid EVtools Product Owner`;
    },
  },

  /**
   * You could use an argument to confirm if validation must be used,
   * but also argument you can work like this:
   * this translates to:
   *
   *   validation: {
   *     matchString: "matchThis",
   *   }
   * meaning only if value is exactly the string "matchThis"
   * it will validate to true
   */
  {
    validationKey: "matchString",
    validationArgumentKey: "matchString",
    ruleFunction: (matchString) => (value) => {
      return String(value).includes(matchString)
        ? true
        : `value should spell: ${matchString}`;
    },
  },

  /**
   * CUSTOM RULES
   * Below you find a set of custom rules
   */

  {
    validationKey: "oneLineAllowedCharactersOnly",
    validationArgumentKey: "oneLineAllowedCharactersOnly",
    ruleFunction: (argument) => (value) => {
      if (!value || argument === false) {
        return true;
      }
      return /^([\u00c0-\u01ffa-zA-Z0-9'\-,._@€+!?&:;()#/" ])+$/.test(value)
        ? true
        : em.ALLOWED_CHARACTERS_ONLY;
    },
  },

  {
    validationKey: "multiLineAllowedCharactersOnly",
    validationArgumentKey: "multiLineAllowedCharactersOnly",
    ruleFunction: (argument) => (value) => {
      if (!value || argument === false) {
        return true;
      }
      return /^([\u00c0-\u01ffa-zA-Z0-9'\-,._@€+!?&:;()#/" \r\n])+$/.test(value)
        ? true
        : em.ALLOWED_CHARACTERS_ONLY;
    },
  },

  {
    validationKey: "minimumLength",
    validationArgumentKey: "minimumLength",
    ruleFunction: (minimumLength) => (value) => {
      value = String(value);
      const errorMessage = `${em.MINIMAL_LENGTH} ${minimumLength}`;
      return value.length >= minimumLength ? true : errorMessage;
    },
  },

  {
    validationKey: "maximumLength",
    validationArgumentKey: "maximumLength",
    ruleFunction: (maximumLength) => (value) => {
      value = String(value);
      const errorMessage = `${em.MAXIMUM_LENGTH} ${maximumLength}`;
      return value.length <= maximumLength ? true : errorMessage;
    },
  },

  {
    validationKey: "isDutchPostalCode",
    validationArgumentKey: "isDutchPostalCode",
    ruleFunction: (argument) => (value) => {
      if (!value || argument === false) {
        return true;
      }
      return /^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/i.test(value)
        ? true
        : em.IS_DUTCH_POSTAL_CODE;
    },
  },

  {
    validationKey: "isBelgianPostalCode",
    validationArgumentKey: "isBelgianPostalCode",
    ruleFunction: (argument) => (value) => {
      if (!value || argument === false) {
        return true;
      }
      return /^[1-9]\d{3}$/i.test(value) ? true : em.IS_BELGIAN_POSTAL_CODE;
    },
  },

  {
    validationKey: "validEmail",
    validationArgumentKey: "validEmail",
    ruleFunction: (argument) => (value) => {
      if (!value || argument === false) {
        return true;
      }
      return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test(
        value
      )
        ? true
        : em.VALID_EMAIL;
    },
  },

  {
    validationKey: "validPhoneNumber",
    validationArgumentKey: "validPhoneNumber",
    ruleFunction: (argument) => (value) => {
      if (!value || argument === false) {
        return true;
      }
      return /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{11}$)/.test(
        value
      )
        ? true
        : em.VALID_PHONE_NUMBER;
    },
  },

  {
    validationKey: "agreed",
    validationArgumentKey: "agreed",
    ruleFunction: (argument) => (value) => {
      if (argument === false) {
        // if the value agreed is set to false in the model
        // this rule should (positively validate)
        return true;
      }
      const isBoolean = typeof value === "boolean";
      const booleanTrue = isBoolean && value === true;
      return booleanTrue || value === 1 || value === "true" ? true : em.AGREED;
    },
  },

  {
    validationKey: "isBoolean",
    validationArgumentKey: "isBoolean",
    ruleFunction: (argument) => (value) => {
      if (argument === false) {
        return true;
      }
      return typeof value === "boolean" ? true : em.INVALID_DATA_TYPE;
    },
  },

  {
    validationKey: "isValidFileArray",
    validationArgumentKey: "isValidFileArray",
    ruleFunction: (argument) => (fileArray) => {
      if (!argument) {
        return true;
      }

      if (!Array.isArray(fileArray)) {
        return em.INVALID_DATA_TYPE;
      }

      if (fileArray.length === 0) {
        return true;
      }

      const oneLineAllowedCharactersOnly =
        /^([a-zA-Z0-9'\-,._@+!?&:;()#/= ])+$/;
      const minimumDate = 1643890569936;
      const maximumDate = 3643890569936;
      // then validate each of the elements

      const errors = [];

      fileArray.forEach((fileObject) => {
        if (
          (Object.keys(fileObject).length !== 4 &&
            Object.keys(fileObject).length !== 5) ||
          !Object.hasOwnProperty.call(fileObject, "id") ||
          !Object.hasOwnProperty.call(fileObject, "name") ||
          !Object.hasOwnProperty.call(fileObject, "ext") ||
          !Object.hasOwnProperty.call(fileObject, "date") ||
          typeof fileObject.id !== "number" ||
          typeof fileObject.name !== "string" ||
          typeof fileObject.ext !== "string" ||
          typeof fileObject.date !== "number" ||
          !oneLineAllowedCharactersOnly.test(fileObject.ext) ||
          fileObject.date < minimumDate ||
          fileObject.date > maximumDate ||
          !oneLineAllowedCharactersOnly.test(fileObject.name)
        ) {
          errors.push("There was an error");
        }

        // Don't validate uuid if it has not been set

        if (
          Object.hasOwnProperty.call(fileObject, "uuid") &&
          (typeof fileObject.uuid !== "string" ||
            !uuidValidate(fileObject.uuid))
        ) {
          errors.push("There was an error");
        }
      });

      if (errors.length !== 0) {
        return em.INVALID_DATA_TYPE;
      }

      return true;
    },
  },

  {
    validationKey: "isValidUuidv4",
    validationArgumentKey: "isValidUuidv4",
    ruleFunction: (argument) => (value) => {
      if (argument === false) {
        return true;
      }
      return /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i.test(
        value
      )
        ? true
        : em.INVALID_UUID_V4;
    },
  },

  {
    validationKey: "minimalNumberOfCheckedBoxes",
    validationArgumentKey: "minimalNumberOfCheckedBoxes",
    ruleFunction: (argument) => (value) => {
      const errorMessage = `${em.MINIMAL_NUMBER_OF_CHECKED} ${parseInt(
        argument
      )} ${em.CHOICES}`;
      return value >= parseInt(argument) ? true : errorMessage;
    },
  },

  {
    validationKey: "validArrayOfCheckboxItems",
    validationArgumentKey: "validArrayOfCheckboxItems",
    backendOnly: true,
    ruleFunction: (argument) => (checkBoxItems) => {
      if (argument === false) {
        return true;
      }

      const oneLineAllowedCharactersOnly =
        /^([a-zA-Z0-9'\-,._@+!?&:;()#/= ])+$/;
      // then validate each of the elements

      const errors = [];

      checkBoxItems.forEach((checkBox) => {
        if (
          Object.keys(checkBox).length !== 3 ||
          !Object.hasOwnProperty.call(checkBox, "id") ||
          !Object.hasOwnProperty.call(checkBox, "title") ||
          !Object.hasOwnProperty.call(checkBox, "value") ||
          typeof checkBox.id !== "string" ||
          typeof checkBox.title !== "string" ||
          (typeof checkBox.value !== "boolean" && checkBox.value !== null) ||
          !oneLineAllowedCharactersOnly.test(checkBox.id) ||
          !oneLineAllowedCharactersOnly.test(checkBox.title)
        ) {
          errors.push("There was an error");
        }
      });

      return errors.length === 0 ? true : em.INVALID_DATA_TYPE;
    },
  },
];
