const deepMerge = require("deepmerge");
const {defaultErrorMessages} = require("./default-error-messages")
const {errorMessagesNL} = require("./languages/error-messages-nl")
const {errorMessagesGE} = require("./languages/error-messages-ge")
const {errorMessagesFR} = require("./languages/error-messages-fr")

module.exports.errorMessages = {
  en: defaultErrorMessages,
  nl: deepMerge(defaultErrorMessages, errorMessagesNL),
  ge: deepMerge(defaultErrorMessages, errorMessagesGE),
  fr: deepMerge(defaultErrorMessages, errorMessagesFR),
}
