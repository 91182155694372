import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API
axios.defaults.headers = {
  'X-API-KEY': process.env.VUE_APP_BACKEND_API_KEY,
}
axios.defaults.timeout = 15000

// eslint-disable-next-line no-console
const aws = {
  namespaced: true,
  actions: {
    async getUploadURL(context, { uuid, category, filename, extension }) {
      const tenantId = process.env.VUE_APP_CURRENT_TENANT
      const backendUrl = process.env.VUE_APP_BACKEND_API
      const url = `${backendUrl}/v1/${tenantId/**/}/charging-point-requests/${uuid}/files/upload-link`

      const data = {
        category, // internal key allowed types: ['vehicle', 'parkingspot']
        filename,
        extension,
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': process.env.VUE_APP_BACKEND_API_KEY,
        },
        body: JSON.stringify(data),
      })

      // ToDo: implement all possible exceptions of failure

      if(response.ok !== true) {
        throw new Error('File upload failed')
      }
      const jsonBody = await response.json()

      if (!jsonBody.link) {
        throw new Error('File upload failed')
      }
      return jsonBody
    },
    async uploadFile(context, { fileObject, uploadUrl }) {
      const headers = {
        'Content-Type': fileObject.file.type,
      }
      return await axios.put(uploadUrl, fileObject.file, { headers })
    },
  },
}
export { aws }
