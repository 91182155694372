module.exports.gemeentesOpcharge = [
  {
    "code": "1680",
    "name": "Aa en Hunze"
  },
  {
    "code": "0358",
    "name": "Aalsmeer"
  },
  {
    "code": "0197",
    "name": "Aalten"
  },
  {
    "code": "0059",
    "name": "Achtkarspelen"
  },
  {
    "code": "0482",
    "name": "Alblasserdam"
  },
  {
    "code": "0613",
    "name": "Albrandswaard"
  },
  {
    "code": "0361",
    "name": "Alkmaar"
  },
  {
    "code": "0141",
    "name": "Almelo"
  },
  {
    "code": "0034",
    "name": "Almere"
  },
  {
    "code": "0484",
    "name": "Alphen aan den Rijn"
  },
  {
    "code": "1723",
    "name": "Alphen-Chaam"
  },
  {
    "code": "1959",
    "name": "Altena"
  },
  {
    "code": "0060",
    "name": "Ameland"
  },
  {
    "code": "0307",
    "name": "Amersfoort"
  },
  {
    "code": "0362",
    "name": "Amstelveen"
  },
  {
    "code": "0363",
    "name": "Amsterdam"
  },
  {
    "code": "0200",
    "name": "Apeldoorn"
  },
  {
    "code": "0202",
    "name": "Arnhem"
  },
  {
    "code": "0106",
    "name": "Assen"
  },
  {
    "code": "0743",
    "name": "Asten"
  },
  {
    "code": "0744",
    "name": "Baarle-Nassau"
  },
  {
    "code": "0308",
    "name": "Baarn"
  },
  {
    "code": "0489",
    "name": "Barendrecht"
  },
  {
    "code": "0203",
    "name": "Barneveld"
  },
  {
    "code": "0888",
    "name": "Beek"
  },
  {
    "code": "1954",
    "name": "Beekdaelen"
  },
  {
    "code": "0889",
    "name": "Beesel"
  },
  {
    "code": "1945",
    "name": "Berg en Dal"
  },
  {
    "code": "1724",
    "name": "Bergeijk"
  },
  {
    "code": "0373",
    "name": "Bergen (NH.)"
  },
  {
    "code": "0748",
    "name": "Bergen op Zoom"
  },
  {
    "code": "1859",
    "name": "Berkelland"
  },
  {
    "code": "1721",
    "name": "Bernheze"
  },
  {
    "code": "0753",
    "name": "Best"
  },
  {
    "code": "0209",
    "name": "Beuningen"
  },
  {
    "code": "0375",
    "name": "Beverwijk"
  },
  {
    "code": "0310",
    "name": "De Bilt"
  },
  {
    "code": "1728",
    "name": "Bladel"
  },
  {
    "code": "0376",
    "name": "Blaricum"
  },
  {
    "code": "0377",
    "name": "Bloemendaal"
  },
  {
    "code": "1901",
    "name": "Bodegraven-Reeuwijk"
  },
  {
    "code": "0755",
    "name": "Boekel"
  },
  {
    "code": "1681",
    "name": "Borger-Odoorn"
  },
  {
    "code": "0147",
    "name": "Borne"
  },
  {
    "code": "0654",
    "name": "Borsele"
  },
  {
    "code": "0757",
    "name": "Boxtel"
  },
  {
    "code": "0758",
    "name": "Breda"
  },
  {
    "code": "0501",
    "name": "Brielle"
  },
  {
    "code": "1876",
    "name": "Bronckhorst"
  },
  {
    "code": "0213",
    "name": "Brummen"
  },
  {
    "code": "0899",
    "name": "Brunssum"
  },
  {
    "code": "0312",
    "name": "Bunnik"
  },
  {
    "code": "0313",
    "name": "Bunschoten"
  },
  {
    "code": "0214",
    "name": "Buren"
  },
  {
    "code": "0502",
    "name": "Capelle aan den IJssel"
  },
  {
    "code": "0383",
    "name": "Castricum"
  },
  {
    "code": "0109",
    "name": "Coevorden"
  },
  {
    "code": "1706",
    "name": "Cranendonck"
  },
  {
    "code": "0216",
    "name": "Culemborg"
  },
  {
    "code": "0148",
    "name": "Dalfsen"
  },
  {
    "code": "1891",
    "name": "Dantumadiel"
  },
  {
    "code": "0503",
    "name": "Delft"
  },
  {
    "code": "0400",
    "name": "Den Helder"
  },
  {
    "code": "0762",
    "name": "Deurne"
  },
  {
    "code": "0150",
    "name": "Deventer"
  },
  {
    "code": "0384",
    "name": "Diemen"
  },
  {
    "code": "1774",
    "name": "Dinkelland"
  },
  {
    "code": "0221",
    "name": "Doesburg"
  },
  {
    "code": "0222",
    "name": "Doetinchem"
  },
  {
    "code": "0766",
    "name": "Dongen"
  },
  {
    "code": "0505",
    "name": "Dordrecht"
  },
  {
    "code": "0498",
    "name": "Drechterland"
  },
  {
    "code": "1719",
    "name": "Drimmelen"
  },
  {
    "code": "0303",
    "name": "Dronten"
  },
  {
    "code": "0225",
    "name": "Druten"
  },
  {
    "code": "0226",
    "name": "Duiven"
  },
  {
    "code": "1711",
    "name": "Echt-Susteren"
  },
  {
    "code": "0385",
    "name": "Edam-Volendam"
  },
  {
    "code": "0228",
    "name": "Ede"
  },
  {
    "code": "0317",
    "name": "Eemnes"
  },
  {
    "code": "0770",
    "name": "Eersel"
  },
  {
    "code": "1903",
    "name": "Eijsden-Margraten"
  },
  {
    "code": "0772",
    "name": "Eindhoven"
  },
  {
    "code": "0230",
    "name": "Elburg"
  },
  {
    "code": "0114",
    "name": "Emmen"
  },
  {
    "code": "0388",
    "name": "Enkhuizen"
  },
  {
    "code": "0153",
    "name": "Enschede"
  },
  {
    "code": "0232",
    "name": "Epe"
  },
  {
    "code": "0233",
    "name": "Ermelo"
  },
  {
    "code": "0777",
    "name": "Etten-Leur"
  },
  {
    "code": "1940",
    "name": "De Fryske Marren"
  },
  {
    "code": "0779",
    "name": "Geertruidenberg"
  },
  {
    "code": "1771",
    "name": "Geldrop-Mierlo"
  },
  {
    "code": "1652",
    "name": "Gemert-Bakel"
  },
  {
    "code": "0907",
    "name": "Gennep"
  },
  {
    "code": "0784",
    "name": "Gilze en Rijen"
  },
  {
    "code": "1924",
    "name": "Goeree-Overflakkee"
  },
  {
    "code": "0664",
    "name": "Goes"
  },
  {
    "code": "0785",
    "name": "Goirle"
  },
  {
    "code": "1942",
    "name": "Gooise Meren"
  },
  {
    "code": "0512",
    "name": "Gorinchem"
  },
  {
    "code": "0513",
    "name": "Gouda"
  },
  {
    "code": "0518",
    "name": "'s-Gravenhage"
  },
  {
    "code": "0014",
    "name": "Groningen"
  },
  {
    "code": "1729",
    "name": "Gulpen-Wittem"
  },
  {
    "code": "0158",
    "name": "Haaksbergen"
  },
  {
    "code": "0392",
    "name": "Haarlem"
  },
  {
    "code": "0394",
    "name": "Haarlemmermeer"
  },
  {
    "code": "1655",
    "name": "Halderberge"
  },
  {
    "code": "0160",
    "name": "Hardenberg"
  },
  {
    "code": "0243",
    "name": "Harderwijk"
  },
  {
    "code": "0523",
    "name": "Hardinxveld-Giessendam"
  },
  {
    "code": "0072",
    "name": "Harlingen"
  },
  {
    "code": "0244",
    "name": "Hattem"
  },
  {
    "code": "0396",
    "name": "Heemskerk"
  },
  {
    "code": "0397",
    "name": "Heemstede"
  },
  {
    "code": "0246",
    "name": "Heerde"
  },
  {
    "code": "0074",
    "name": "Heerenveen"
  },
  {
    "code": "0917",
    "name": "Heerlen"
  },
  {
    "code": "1658",
    "name": "Heeze-Leende"
  },
  {
    "code": "0399",
    "name": "Heiloo"
  },
  {
    "code": "0163",
    "name": "Hellendoorn"
  },
  {
    "code": "0530",
    "name": "Hellevoetsluis"
  },
  {
    "code": "0794",
    "name": "Helmond"
  },
  {
    "code": "0531",
    "name": "Hendrik-Ido-Ambacht"
  },
  {
    "code": "0164",
    "name": "Hengelo"
  },
  {
    "code": "0796",
    "name": "'s-Hertogenbosch"
  },
  {
    "code": "0252",
    "name": "Heumen"
  },
  {
    "code": "0797",
    "name": "Heusden"
  },
  {
    "code": "0534",
    "name": "Hillegom"
  },
  {
    "code": "0798",
    "name": "Hilvarenbeek"
  },
  {
    "code": "0402",
    "name": "Hilversum"
  },
  {
    "code": "1963",
    "name": "Hoeksche Waard"
  },
  {
    "code": "1735",
    "name": "Hof van Twente"
  },
  {
    "code": "1966",
    "name": "Het Hogeland"
  },
  {
    "code": "1911",
    "name": "Hollands Kroon"
  },
  {
    "code": "0118",
    "name": "Hoogeveen"
  },
  {
    "code": "0405",
    "name": "Hoorn"
  },
  {
    "code": "1507",
    "name": "Horst aan de Maas"
  },
  {
    "code": "0321",
    "name": "Houten"
  },
  {
    "code": "0406",
    "name": "Huizen"
  },
  {
    "code": "0677",
    "name": "Hulst"
  },
  {
    "code": "0353",
    "name": "IJsselstein"
  },
  {
    "code": "1884",
    "name": "Kaag en Braassem"
  },
  {
    "code": "0166",
    "name": "Kampen"
  },
  {
    "code": "0678",
    "name": "Kapelle"
  },
  {
    "code": "0537",
    "name": "Katwijk"
  },
  {
    "code": "0928",
    "name": "Kerkrade"
  },
  {
    "code": "1598",
    "name": "Koggenland"
  },
  {
    "code": "0542",
    "name": "Krimpen aan den IJssel"
  },
  {
    "code": "1931",
    "name": "Krimpenerwaard"
  },
  {
    "code": "1659",
    "name": "Laarbeek"
  },
  {
    "code": "0882",
    "name": "Landgraaf"
  },
  {
    "code": "0415",
    "name": "Landsmeer"
  },
  {
    "code": "1621",
    "name": "Lansingerland"
  },
  {
    "code": "0417",
    "name": "Laren"
  },
  {
    "code": "0080",
    "name": "Leeuwarden"
  },
  {
    "code": "0546",
    "name": "Leiden"
  },
  {
    "code": "0547",
    "name": "Leiderdorp"
  },
  {
    "code": "1916",
    "name": "Leidschendam-Voorburg"
  },
  {
    "code": "0995",
    "name": "Lelystad"
  },
  {
    "code": "1640",
    "name": "Leudal"
  },
  {
    "code": "0327",
    "name": "Leusden"
  },
  {
    "code": "1705",
    "name": "Lingewaard"
  },
  {
    "code": "0553",
    "name": "Lisse"
  },
  {
    "code": "0262",
    "name": "Lochem"
  },
  {
    "code": "0809",
    "name": "Loon op Zand"
  },
  {
    "code": "0331",
    "name": "Lopik"
  },
  {
    "code": "0168",
    "name": "Losser"
  },
  {
    "code": "0263",
    "name": "Maasdriel"
  },
  {
    "code": "1641",
    "name": "Maasgouw"
  },
  {
    "code": "0556",
    "name": "Maassluis"
  },
  {
    "code": "0935",
    "name": "Maastricht"
  },
  {
    "code": "0420",
    "name": "Medemblik"
  },
  {
    "code": "0938",
    "name": "Meerssen"
  },
  {
    "code": "1948",
    "name": "Meierijstad"
  },
  {
    "code": "0119",
    "name": "Meppel"
  },
  {
    "code": "0687",
    "name": "Middelburg"
  },
  {
    "code": "1842",
    "name": "Midden-Delfland"
  },
  {
    "code": "1731",
    "name": "Midden-Drenthe"
  },
  {
    "code": "1952",
    "name": "Midden-Groningen"
  },
  {
    "code": "1709",
    "name": "Moerdijk"
  },
  {
    "code": "1978",
    "name": "Molenlanden"
  },
  {
    "code": "1955",
    "name": "Montferland"
  },
  {
    "code": "0335",
    "name": "Montfoort"
  },
  {
    "code": "0944",
    "name": "Mook en Middelaar"
  },
  {
    "code": "1740",
    "name": "Neder-Betuwe"
  },
  {
    "code": "0946",
    "name": "Nederweert"
  },
  {
    "code": "0356",
    "name": "Nieuwegein"
  },
  {
    "code": "0569",
    "name": "Nieuwkoop"
  },
  {
    "code": "0267",
    "name": "Nijkerk"
  },
  {
    "code": "0268",
    "name": "Nijmegen"
  },
  {
    "code": "1930",
    "name": "Nissewaard"
  },
  {
    "code": "1970",
    "name": "Noardeast-Fryslân"
  },
  {
    "code": "1695",
    "name": "Noord-Beveland"
  },
  {
    "code": "1699",
    "name": "Noordenveld"
  },
  {
    "code": "0171",
    "name": "Noordoostpolder"
  },
  {
    "code": "0575",
    "name": "Noordwijk"
  },
  {
    "code": "0820",
    "name": "Nuenen, Gerwen en Nederwetten"
  },
  {
    "code": "0302",
    "name": "Nunspeet"
  },
  {
    "code": "0579",
    "name": "Oegstgeest"
  },
  {
    "code": "0823",
    "name": "Oirschot"
  },
  {
    "code": "0824",
    "name": "Oisterwijk"
  },
  {
    "code": "1895",
    "name": "Oldambt"
  },
  {
    "code": "0269",
    "name": "Oldebroek"
  },
  {
    "code": "0173",
    "name": "Oldenzaal"
  },
  {
    "code": "1773",
    "name": "Olst-Wijhe"
  },
  {
    "code": "0175",
    "name": "Ommen"
  },
  {
    "code": "1586",
    "name": "Oost Gelre"
  },
  {
    "code": "0826",
    "name": "Oosterhout"
  },
  {
    "code": "0085",
    "name": "Ooststellingwerf"
  },
  {
    "code": "0431",
    "name": "Oostzaan"
  },
  {
    "code": "0432",
    "name": "Opmeer"
  },
  {
    "code": "0086",
    "name": "Opsterland"
  },
  {
    "code": "0828",
    "name": "Oss"
  },
  {
    "code": "1509",
    "name": "Oude IJsselstreek"
  },
  {
    "code": "0437",
    "name": "Ouder-Amstel"
  },
  {
    "code": "0589",
    "name": "Oudewater"
  },
  {
    "code": "1734",
    "name": "Overbetuwe"
  },
  {
    "code": "0590",
    "name": "Papendrecht"
  },
  {
    "code": "1894",
    "name": "Peel en Maas"
  },
  {
    "code": "0765",
    "name": "Pekela"
  },
  {
    "code": "1926",
    "name": "Pijnacker-Nootdorp"
  },
  {
    "code": "0439",
    "name": "Purmerend"
  },
  {
    "code": "0273",
    "name": "Putten"
  },
  {
    "code": "0177",
    "name": "Raalte"
  },
  {
    "code": "0703",
    "name": "Reimerswaal"
  },
  {
    "code": "0274",
    "name": "Renkum"
  },
  {
    "code": "0339",
    "name": "Renswoude"
  },
  {
    "code": "1667",
    "name": "Reusel-De Mierden"
  },
  {
    "code": "0275",
    "name": "Rheden"
  },
  {
    "code": "0340",
    "name": "Rhenen"
  },
  {
    "code": "0597",
    "name": "Ridderkerk"
  },
  {
    "code": "1742",
    "name": "Rijssen-Holten"
  },
  {
    "code": "0603",
    "name": "Rijswijk"
  },
  {
    "code": "1669",
    "name": "Roerdalen"
  },
  {
    "code": "0957",
    "name": "Roermond"
  },
  {
    "code": "0736",
    "name": "De Ronde Venen"
  },
  {
    "code": "1674",
    "name": "Roosendaal"
  },
  {
    "code": "0599",
    "name": "Rotterdam"
  },
  {
    "code": "0277",
    "name": "Rozendaal"
  },
  {
    "code": "0840",
    "name": "Rucphen"
  },
  {
    "code": "0441",
    "name": "Schagen"
  },
  {
    "code": "0279",
    "name": "Scherpenzeel"
  },
  {
    "code": "0606",
    "name": "Schiedam"
  },
  {
    "code": "0088",
    "name": "Schiermonnikoog"
  },
  {
    "code": "1676",
    "name": "Schouwen-Duiveland"
  },
  {
    "code": "0965",
    "name": "Simpelveld"
  },
  {
    "code": "0845",
    "name": "Sint-Michielsgestel"
  },
  {
    "code": "1883",
    "name": "Sittard-Geleen"
  },
  {
    "code": "0610",
    "name": "Sliedrecht"
  },
  {
    "code": "1714",
    "name": "Sluis"
  },
  {
    "code": "0090",
    "name": "Smallingerland"
  },
  {
    "code": "0342",
    "name": "Soest"
  },
  {
    "code": "0847",
    "name": "Someren"
  },
  {
    "code": "0848",
    "name": "Son en Breugel"
  },
  {
    "code": "0037",
    "name": "Stadskanaal"
  },
  {
    "code": "0180",
    "name": "Staphorst"
  },
  {
    "code": "0532",
    "name": "Stede Broec"
  },
  {
    "code": "0851",
    "name": "Steenbergen"
  },
  {
    "code": "1708",
    "name": "Steenwijkerland"
  },
  {
    "code": "0971",
    "name": "Stein"
  },
  {
    "code": "1904",
    "name": "Stichtse Vecht"
  },
  {
    "code": "1900",
    "name": "Súdwest-Fryslân"
  },
  {
    "code": "0715",
    "name": "Terneuzen"
  },
  {
    "code": "0093",
    "name": "Terschelling"
  },
  {
    "code": "0448",
    "name": "Texel"
  },
  {
    "code": "1525",
    "name": "Teylingen"
  },
  {
    "code": "0716",
    "name": "Tholen"
  },
  {
    "code": "0281",
    "name": "Tiel"
  },
  {
    "code": "0855",
    "name": "Tilburg"
  },
  {
    "code": "0183",
    "name": "Tubbergen"
  },
  {
    "code": "1700",
    "name": "Twenterand"
  },
  {
    "code": "1730",
    "name": "Tynaarlo"
  },
  {
    "code": "0737",
    "name": "Tytsjerksteradiel"
  },
  {
    "code": "0450",
    "name": "Uitgeest"
  },
  {
    "code": "0451",
    "name": "Uithoorn"
  },
  {
    "code": "0184",
    "name": "Urk"
  },
  {
    "code": "0344",
    "name": "Utrecht"
  },
  {
    "code": "1581",
    "name": "Utrechtse Heuvelrug"
  },
  {
    "code": "0981",
    "name": "Vaals"
  },
  {
    "code": "0994",
    "name": "Valkenburg aan de Geul"
  },
  {
    "code": "0858",
    "name": "Valkenswaard"
  },
  {
    "code": "0047",
    "name": "Veendam"
  },
  {
    "code": "0345",
    "name": "Veenendaal"
  },
  {
    "code": "0717",
    "name": "Veere"
  },
  {
    "code": "0861",
    "name": "Veldhoven"
  },
  {
    "code": "0453",
    "name": "Velsen"
  },
  {
    "code": "0983",
    "name": "Venlo"
  },
  {
    "code": "0984",
    "name": "Venray"
  },
  {
    "code": "1961",
    "name": "Vijfheerenlanden"
  },
  {
    "code": "0622",
    "name": "Vlaardingen"
  },
  {
    "code": "0096",
    "name": "Vlieland"
  },
  {
    "code": "0718",
    "name": "Vlissingen"
  },
  {
    "code": "0986",
    "name": "Voerendaal"
  },
  {
    "code": "0626",
    "name": "Voorschoten"
  },
  {
    "code": "0285",
    "name": "Voorst"
  },
  {
    "code": "0865",
    "name": "Vught"
  },
  {
    "code": "1949",
    "name": "Waadhoeke"
  },
  {
    "code": "0866",
    "name": "Waalre"
  },
  {
    "code": "0867",
    "name": "Waalwijk"
  },
  {
    "code": "0627",
    "name": "Waddinxveen"
  },
  {
    "code": "0289",
    "name": "Wageningen"
  },
  {
    "code": "0629",
    "name": "Wassenaar"
  },
  {
    "code": "0852",
    "name": "Waterland"
  },
  {
    "code": "0988",
    "name": "Weert"
  },
  {
    "code": "0457",
    "name": "Weesp"
  },
  {
    "code": "1960",
    "name": "West Betuwe"
  },
  {
    "code": "0668",
    "name": "West Maas en Waal"
  },
  {
    "code": "1969",
    "name": "Westerkwartier"
  },
  {
    "code": "1701",
    "name": "Westerveld"
  },
  {
    "code": "0293",
    "name": "Westervoort"
  },
  {
    "code": "1950",
    "name": "Westerwolde"
  },
  {
    "code": "1783",
    "name": "Westland"
  },
  {
    "code": "0098",
    "name": "Weststellingwerf"
  },
  {
    "code": "0614",
    "name": "Westvoorne"
  },
  {
    "code": "0189",
    "name": "Wierden"
  },
  {
    "code": "0296",
    "name": "Wijchen"
  },
  {
    "code": "1696",
    "name": "Wijdemeren"
  },
  {
    "code": "0352",
    "name": "Wijk bij Duurstede"
  },
  {
    "code": "0294",
    "name": "Winterswijk"
  },
  {
    "code": "0873",
    "name": "Woensdrecht"
  },
  {
    "code": "0632",
    "name": "Woerden"
  },
  {
    "code": "1690",
    "name": "De Wolden"
  },
  {
    "code": "0880",
    "name": "Wormerland"
  },
  {
    "code": "0351",
    "name": "Woudenberg"
  },
  {
    "code": "0479",
    "name": "Zaanstad"
  },
  {
    "code": "0297",
    "name": "Zaltbommel"
  },
  {
    "code": "0473",
    "name": "Zandvoort"
  },
  {
    "code": "0050",
    "name": "Zeewolde"
  },
  {
    "code": "0355",
    "name": "Zeist"
  },
  {
    "code": "0299",
    "name": "Zevenaar"
  },
  {
    "code": "0637",
    "name": "Zoetermeer"
  },
  {
    "code": "0638",
    "name": "Zoeterwoude"
  },
  {
    "code": "1892",
    "name": "Zuidplas"
  },
  {
    "code": "0879",
    "name": "Zundert"
  },
  {
    "code": "0301",
    "name": "Zutphen"
  },
  {
    "code": "1896",
    "name": "Zwartewaterland"
  },
  {
    "code": "0642",
    "name": "Zwijndrecht"
  },
  {
    "code": "0193",
    "name": "Zwolle"
  },
  {"code": "0003", "name": "Appingedam"},
  {"code": "0893", "name": "Bergen (L.)"},
  {"code": "0373", "name": "Bergen (NH.)"},
  {"code": "0010", "name": "Delfzijl"},
  {"code": "0788", "name": "Haaren"},
  {"code": "0024", "name": "Loppersum"},
  {"code": "0439", "name": "Beemster"},
  {"code": "1982", "name": "Boxmeer"},
  {"code": "1982", "name": "Cuijk"},
  {"code": "1982", "name": "Grave"},
  {"code": "1980", "name": "Heerhugowaard"},
  {"code": "1991", "name": "Landerd"},
  {"code": "1980", "name": "Langedijk"},
  {"code": "1982", "name": "Mill en Sint Hubert"},
  {"code": "1982", "name": "Sint Anthonis"},
  {"code": "1991", "name": "Uden"},
];
