import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import store from './store'
import { StatefulForms } from '@evtools/accow-stateful-forms/stateful-forms'
import { registerCustomFormItems } from './asf-custom/register-custom-form-items'
import { currentTenantConfig } from './tenant-configs'
import axios from 'axios'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [],
})

const statefulFormsOptions = {
  // If you use a form in your application that is not fetched via
  // an API you can register it here

  applicationForms: {
    [currentTenantConfig.formConfigMergedWithModel.formName]:
      currentTenantConfig.formConfigMergedWithModel,
  },

  // During development it could be easy to take a look at the component
  // overview, to do so you have to inject the router and the following
  // router options to make those available

  router,
  routerOptions: {
    addComponentOverviewRoute: true,
    addManualRoute: true,
    addSettingsRoute: true,
  },

  // For debugging you have the following options available

  debugging: {
    showDebugBar: false,
    showMutationSnackbar: false,
    showPanelAndFormIds: false,
    showPanelDebugger: false,
    showFormItemDebugger: false,
    showPanelDebugModal: false,
  },

  // You can overwrite the application vuetify theme defaults here

  vuetifyOptions: currentTenantConfig.themingDefaults,

  // You can register your custom form-items here
  // You have to register the component with Vue first: e.g.
  // Vue.component("EVToolsTextFieldCustom", EVToolsTextFieldCustom);
  // and after that you can register it here:

  registerCustomFormItems: registerCustomFormItems,

  // You can register your custom renamables that will do a string replacement
  // of any given string combination. NOTE: to make sure, wrap renamables in something
  // like dollar-signs or brackets.
  // It will replace all occurrences in formItem.label or formItem.title

  customRenamables: {
    '$COMPANY$': 'EVtools',
  },

  customErrorMessages: currentTenantConfig.customErrorMessages || undefined,

  // You can register your custom validationRules here

  customValidationRuleDefinitions:
    currentTenantConfig.customValidationRuleDefinitions,
}

Vue.use(StatefulForms, store, statefulFormsOptions)

Vue.config.productionTip = false

// Define applicationRoutes for route and menu-item rendering

const applicationRoutes = [
  {
    path: '/',
    component: currentTenantConfig.component,
    props: {
      formName: currentTenantConfig.formConfigMergedWithModel.formName,
    },
  },
]

// Set tenant specific data in project store

store.commit(
  'Project/setTenantSpecificData',
  currentTenantConfig.tenantSpecificData,
)

// add the application routes to the router

applicationRoutes.forEach((route) => {
  router.addRoute(route)
})

// use applicationRoutes and $asf-custom.routes for menu-item rendering

Vue.prototype.$menuItems = [...applicationRoutes, ...Vue.prototype.$asf.routes]

// set axios defaults

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API
axios.defaults.headers = {
  'X-API-KEY': process.env.VUE_APP_BACKEND_API_KEY,
}
axios.defaults.timeout = 15000

// mount new Vue instance to #app

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
