<template>
  <v-app id="keep">
    <v-main>
      <asf-loading-spinner-wrapper
        style="height: 100vh"
        :show-spinner="showSpinner"
      >
        <router-view :key="$route.fullPath" />
        <asf-mutation-snackbar />
        <asf-debug-bar />
      </asf-loading-spinner-wrapper>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      showSpinner: true,
    }
  },
  mounted() {
    // This is just for example purpose only

    setTimeout(() => {
      this.showSpinner = false
    }, 200)
  },
}
</script>
