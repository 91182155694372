module.exports.modelParkNCharge = {
  naam: {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 1,
      maximumLength: 128
    }
  },
  straatnaam: {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 2,
      maximumLength: 64
    }
  },
  huisnummer: {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 1,
      maximumLength: 5
    }
  },
  toevoeging: {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      maximumLength: 5
    }
  },
  postcode: {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      isDutchPostalCode: true,
    }
  },
  woonplaats: {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      required: true,
      maximumLength: 128
    }
  },
  gemeente: {
    dataType: "object",
    validation: {
      required: true
    },
    validation_back_end: {
      isValidObject: true,
      idKey: "code",
      stringKey: "name",
      stringValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 64,
      },
      idValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 5,
      },
    }
  },
  email: {
    dataType: "string",
    validation: {
      required: true,
      validEmail: true,
      maximumLength: 128
    }
  },
  telefoon: {
    dataType: "string",
    validation: {
      required: true,
      validPhoneNumber: true,
      oneLineAllowedCharactersOnly: true,
      maximumLength: 20
    }
  },
  locatie: {
    dataType: "string",
    validation: {
      required: true,
      minimumLength: 3,
      maximumLength: 256,
      multiLineAllowedCharactersOnly: true,
    }
  },
  bericht: {
    dataType: "string",
    validation: {
      required: false,
      multiLineAllowedCharactersOnly: true,
      maximumLength: 2048
    }
  },
  akkoord: {
    dataType: "string",
    validation: {
      required: false,
      oneLineAllowedCharactersOnly: true,
      maximumLength: 12
    }
  },
};
