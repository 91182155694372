module.exports.modelGoRal = {
  "gemcode": {
    dataType: "object",
    validation: {
      required: true,
    },
    validation_back_end: {
      isValidObject: true,
      idKey: "code",
      stringKey: "name",
      stringValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 64,
      },
      idValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 5,
      },
    },
  },
  "conditions.vehicle": {
    dataType: "object",
    validation: {
      required: true,
    },
    validation_front_end: {
      radioValueMustBe: 1
    },
    validation_back_end: {
      isValidObject: true,
      idKey: "id",
      stringKey: "title",
      stringValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 64,
      },
      idValue: {
        isInteger: true,
        minimum: 1,
        maximum: 2,
      },
    },
  },
  "conditions.parkingspace": {
    dataType: "object",
    validation: {
      required: true,
    },
    validation_front_end: {
      radioValueMustBe: 2
    },
    validation_back_end: {
      isValidObject: true,
      idKey: "id",
      stringKey: "title",
      stringValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 64,
      },
      idValue: {
        isInteger: true,
        minimum: 1,
        maximum: 2,
      },
    },
  },
  "vehicle.brand": {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 2,
      maximumLength: 64,
    },
  },
  "vehicle.model": {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 2,
      maximumLength: 64,
    },
  },

  // ToDo: this is a file

  "vehicle.upload": {
    dataType: "array",
    validation_front_end: {
      required: true
    },
    validation_back_end: {
      isValidFileArray: true
    }
  },

  "vehicle.upload.2": {
    dataType: "array",
    validation_back_end: {
      isValidFileArray: true
    }
  },

  "vehicle.upload.3": {
    dataType: "array",
    validation_back_end: {
      isValidFileArray: true
    }
  },

  "parkingspot.permit": {
    dataType: "object",
    validation: {
      required: true,
    },
    validation_back_end: {
      isValidObject: true,
      idKey: "id",
      stringKey: "title",
      stringValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 64,
      },
      idValue: {
        isInteger: true,
        minimum: 1,
        maximum: 2,
      },
    },
  },

  "parkingspot.upload": {
    dataType: "array",
    validation_back_end: {
      isValidFileArray: true
    }
  },

  "parkingspot.reservedspot": {
    dataType: "object",
    validation: {
      required: true,
    },
    validation_back_end: {
      isValidObject: true,
      idKey: "id",
      stringKey: "title",
      stringValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 64,
      },
      idValue: {
        isInteger: true,
        minimum: 1,
        maximum: 2,
      },
    },
  },

  "location.street": {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      maximumLength: 128,
    },
  },

  "location.number": {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 1,
      maximumLength: 10,
    },
  },

  "location.suffix": {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      maximumLength: 10,
    },
  },

  "location.postcode": {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      isDutchPostalCode: true,
      required: true,
    },
  },

  "location.city": {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      required: true,
      maximumLength: 64,
    },
  },

  "context.chargingtime": {
    dataType: "array",
    validation_front_end: {
      minimalNumberOfCheckedBoxes: 1,
    },
    validation_back_end: {
      validArrayOfCheckboxItems: true
    }
  },

  "context.remarks": {
    dataType: "string",
    validation: {
      multiLineAllowedCharactersOnly: true,
      maximumLength: 512,
    },
  },

  "contact.initials": {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      required: true,
      maximumLength: 64,
    },
  },

  "contact.surname": {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      required: true,
      maximumLength: 64,
    },
  },

  "contact.phone": {
    dataType: "string",
    validation: {
      required: true,
      validPhoneNumber: true,
      oneLineAllowedCharactersOnly: true,
      maximumLength: 20,
    },
  },

  "contact.email": {
    dataType: "string",
    validation: {
      required: true,
      validEmail: true,
      maximumLength: 128,
    },
  },

  "toestemming": {
    dataType: "boolean",
    validation: {
      required: true,
    },
  },
};
