import { MapFormToRecordData } from '@evtools/accow-stateful-forms/stateful-forms'
import axios from 'axios'
import { aws } from './aws'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API
axios.defaults.headers = {
  'X-API-KEY': process.env.VUE_APP_BACKEND_API_KEY,
}
axios.defaults.timeout = 15000

const backendUrl = process.env.VUE_APP_BACKEND_API


const project = {
  namespaced: true,
  modules: {
    AWS: aws,
  },
  state: {
    projectDataLoaded: false,
    isLoading: false,
    messageTimeOut: 16, // in seconds
    successMessage: null,
    formHasBeenSentSuccessfully: false,
    errorMessage: null,
    tenantSpecificData: null,
    caseReferences: null,
  },
  getters: {
    gemeentes: (state) => {
      return state.tenantSpecificData.gemeentes.map((item) => {
        return Object.assign(item, { show: { value: true } })
      })
    },
  },
  mutations: {
    setCaseReferences(state, value) {
      state.caseReferences = value
    },
    setProjectDataLoaded(state, value) {
      state.projectDataLoaded = value
    },
    setFormHasBeenSentSuccessfully(state, newValue) {
      state.formHasBeenSentSuccessfully = newValue
    },
    setIsLoading(state, value) {
      state.isLoading = value
    },
    setSuccessMessage(state, value) {
      state.successMessage = value
    },
    setErrorMessage(state, value) {
      state.successMessage = value
    },
    setTenantSpecificData(state, value) {
      state.tenantSpecificData = value
    },
  },
  actions: {
    async sendChargerRequestData(
      { state, commit, rootState, dispatch },
      formName = undefined,
    ) {
      const data = rootState.AsfForms.forms[formName]
      const mapFormToRecordData = new MapFormToRecordData(data.panels)

      const tenantId = process.env.VUE_APP_CURRENT_TENANT
      let response = null
      if (state.caseReferences === null) {
        response = await axios.post(`${backendUrl}/v1/${tenantId}/charging-point-requests-new`, {
          record: mapFormToRecordData.dataRecord,
        })
      } else {
        response = await axios.post(`${backendUrl}/v1/${tenantId}/charging-point-existing-requests`, {
          record: mapFormToRecordData.dataRecord,
          caseReferences: state.caseReferences,
        })
      }

      if (response.status === 200) {
        const message = `${response.data.message}`
        commit('setFormHasBeenSentSuccessfully', true)
        // initialize caseReferences
        commit('setCaseReferences', null)
        dispatch('triggerSuccessMessage', message)
      } else {
        const message = `${response.data.message}`
        dispatch('triggerErrorMessage', message)
      }
    },
    async handleAwsFileUpload({ state, dispatch }, fileObject) {
      if (state.caseReferences === null && fileObject !== null) {
        await dispatch('sendPreliminaryChargerRequestData')
      }
      return await dispatch('uploadFileToAws', fileObject)
    },
    async sendPreliminaryChargerRequestData({ commit, dispatch }) {
      const tenantId = process.env.VUE_APP_CURRENT_TENANT
      const response = await axios.post(
        `${backendUrl}/v1/${tenantId}/charging-point-preliminary-requests`,
        null,
      )
      if (response.status === 200) {
        commit('setCaseReferences', response.data.data)
      } else {
        const message = `${response.data.message}`
        dispatch('triggerErrorMessage', message)
      }
    },
    async uploadFileToAws({ state, dispatch }, fileObject) {
      let filename = fileObject.name
      // get name without extension
      filename = filename.replace(`.${fileObject.ext}`, '')

      const uploadResponseBody = await dispatch('AWS/getUploadURL', {
        uuid: state.caseReferences.uuid,
        category: fileObject.doctype,
        filename,
        extension: fileObject.ext,
      })

      fileObject.caseReferences = state.caseReferences
      fileObject.fileUuid = uploadResponseBody.file.uuid

      fileObject.fileUploaded = await dispatch('AWS/uploadFile', {
        fileObject,
        uploadUrl: uploadResponseBody.link,
      })
      return fileObject
    },
    setFormHasBeenSentSuccessfully({ commit }, value) {
      commit('setFormHasBeenSentSuccessfully', value)
    },
    handleError({ dispatch }, error) {
      if (error?.isAxiosError) {
        const message = error?.response?.data?.message
        dispatch('triggerErrorMessage', message)
      }
      dispatch('triggerErrorMessage', 'An unknown error occurred')
      // eslint-disable-next-line no-console
      console.log('There was an error', error)
    },
    triggerSuccessMessage({ commit }, message) {
      commit('setSuccessMessage', message)
      // setTimeout(() => {
      //   commit("setSuccessMessage", null);
      // }, state.messageTimeOut * 1000);
    },
    triggerErrorMessage({ state, commit }, message) {
      commit('setErrorMessage', message)
      setTimeout(() => {
        commit('setErrorMessage', null)
      }, state.messageTimeOut * 1000)
    },
  },
}
export { project }
