module.exports.errorMessagesGE = {
  REQUIRED: "Dieses Feld ist erforderlich",
  MINIMAL_LENGTH: "Die Mindestanzahl an Zeichen ist",
  MAXIMUM_LENGTH: "Die maximale Anzahl an Zeichen wurde überschritten, Maximum ist",
  MINIMAL_VALUE: "der Wert muss mindestens sein",
  MAXIMUM_VALUE: "der Wert muss höchstens sein",
  INVALID_INTEGER: "Wert ist keine ganze Zahl",
  INVALID_FLOAT:
    "Der Wert ist kein gültiger Gleitkommawert oder eine gültige Zahl, die maximal zulässige Anzahl an Dezimalstellen ist",
  IS_DUTCH_POSTAL_CODE: "Dies ist keine gültige niederländische Postleitzahl",
  VALID_EMAIL: "Dies ist keine gültige e-mail Adresse",
  AGREED: "muss überprüft werden",
  INVALID_DATA_TYPE: "ein ungültiger Datentyp wurde gesendet",
  VALID_PHONE_NUMBER: "Dies ist keine gültige Telefonnummer",
  ALLOWED_CHARACTERS_ONLY: "Es wurden ungültige Zeichen eingegeben",
  THE_FIELD: "das Feld",
  NOT_AVAILABLE_IN_DATA_SET: "ist in den gelieferten Daten nicht vorhanden",
  CANNOT_BE_EMPTY: "darf nicht leer sein",
};
