import Vue from 'vue'
import EVToolsTextFieldCustom from './customFormItems/EVToolsTextFieldCustom'
import EvToolsSingleFileUpload from './customFormItems/EvToolsSingleFileUpload'
import EvToolsMultiCheckbox from './customFormItems/EvToolsMultiCheckbox'

Vue.component('EVToolsTextFieldCustom', EVToolsTextFieldCustom)
Vue.component('EvToolsSingleFileUpload', EvToolsSingleFileUpload)
Vue.component('EvToolsMultiCheckbox', EvToolsMultiCheckbox)

export const registerCustomFormItems = [
  {
    moduleName: 'EVToolsTextFieldCustom',
    formItemType: 'textFieldCustom',
  },
  {
    moduleName: 'EvToolsSingleFileUpload',
    formItemType: 'evToolsSingleFileUpload',
  },
  {
    moduleName: 'EvToolsMultiCheckbox',
    formItemType: 'evToolsMultiCheckbox',
  },
]
