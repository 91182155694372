const {
  formConfigOpCharge,
} = require("./tenants/op-charge/form-config-op-charge");
const { modelOpCharge } = require("./tenants/op-charge/model-op-charge");
const { errorMessages } = require("./error-messages/error-messages");

const { gemeentesOpcharge } = require("./tenants/op-charge/gemeentes-opcharge");
const { formConfigGoRal } = require("./tenants/go-ral/form-config-go-ral");
const { modelGoRal } = require("./tenants/go-ral/model-go-ral");
const {
  modelParkNCharge,
} = require("./tenants/park-n-charge/model-park-n-charge");
const { modelVlaanderen } = require("./tenants/vlaanderen/model-vlaanderen");
const { gemeentesGoRal } = require("./tenants/go-ral/gemeentes-go-ral");
const {
  customValidationRuleDefinitions,
} = require("./validation-rules/custom-validation-rule-definitions");

module.exports = {
  formConfigOpCharge,
  modelOpCharge,
  gemeentesOpcharge,
  formConfigGoRal,
  modelGoRal,
  modelParkNCharge,
  modelVlaanderen,
  gemeentesGoRal,
  errorMessages,
  customValidationRuleDefinitions,
};
