module.exports.modelVlaanderen = {
  dossierID: {
    dataType: 'string',
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 5,
      maximumLength: 30,
    },
  },
  'coordinates.lat': {
    dataType: 'number',
    validation: {
      required: true,
      minimumLength: 7,
    },
  },
  'coordinates.lng': {
    dataType: 'number',
    validation: {
      required: true,
      minimumLength: 7,
    },
  },
  'address.street': {
    dataType: 'string',
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 2,
      maximumLength: 64,
    },
  },
  'address.number': {
    dataType: 'string',
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 1,
      maximumLength: 5,
    },
  },
  'address.suffix': {
    dataType: 'string',
    validation: {
      oneLineAllowedCharactersOnly: true,
      maximumLength: 15,
    },
  },
  'address.postalcode': {
    dataType: 'string',
    validation: {
      required: true,
      isBelgianPostalCode: true,
    },
  },
  'address.city': {
    dataType: 'string',
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      maximumLength: 128,
    },
  },
  'address.municipality.code': {
    dataType: 'string',
    validation: {
      required: true,
      minimumLength: 5,
      maximumLength: 5,
    },
  },
  'address.municipality.name': {
    dataType: 'string',
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      maximumLength: 128,
    },
  },
  'address.country': {
    dataType: 'string',
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      maximumLength: 2,
    },
  },
  created_at: {
    dataType: 'number',
    validation: {
      required: true,
      maximumLength: 13,
      minimumLength: 13,
    },
  },
}
