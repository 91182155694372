module.exports.modelOpCharge = {
  locatie: {
    dataType: "object",
    validation: {
      required: true
    },
    validation_back_end: {
      isValidObject: true,
      idKey: "id",
      stringKey: "title",
      stringValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 64
      },
      idValue: {
        isInteger: true,
        minimum: 1,
        maximum: 2
      }
    }
  },
  wens_straat: {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 2,
      maximumLength: 64
    }
  },
  wens_nummer: {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 1,
      maximumLength: 10
    }
  },
  wens_toevoeging: {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      maximumLength: 10
    }
  },
  wens_postcode: {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      isDutchPostalCode: true,
      required: true
    }
  },
  wens_plaats: {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      required: true,
      maximumLength: 64
    }
  },
  wens_gemeente: {
    dataType: "object",
    validation: {
      required: true
    },
    validation_back_end: {
      isValidObject: true,
      idKey: "code",
      stringKey: "name",
      stringValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 64,
      },
      idValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 5,
      },
    }
  },

  aanhef: {
    dataType: "object",
    validation: {
      required: true
    },
    validation_back_end: {
      isValidObject: true,
      idKey: "id",
      stringKey: "title",
      stringValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 64
      },
      idValue: {
        isInteger: true,
        minimum: 1,
        maximum: 2
      }
    }
  },

  voornaam: {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      required: true,
      maximumLength: 64
    }
  },
  achternaam: {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      required: true,
      maximumLength: 64
    }
  },
  straat: {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 2,
      maximumLength: 64
    }
  },
  nummer: {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      minimumLength: 1,
      maximumLength: 10
    }
  },
  toevoeging: {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      maximumLength: 10
    }
  },
  postcode: {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      isDutchPostalCode: true,
      required: true
    }
  },
  plaats: {
    dataType: "string",
    validation: {
      oneLineAllowedCharactersOnly: true,
      required: true,
      maximumLength: 64
    }
  },
  gemeente: {
    dataType: "object",
    validation: {
      required: true
    },
    validation_back_end: {
      isValidObject: true,
      idKey: "code",
      stringKey: "name",
      stringValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 64,
      },
      idValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 5,
      },
    }
  },
  telefoonnummer: {
    dataType: "string",
    validation: {
      required: true,
      validPhoneNumber: true,
      oneLineAllowedCharactersOnly: true,
      maximumLength: 20
    }
  },
  email: {
    dataType: "string",
    validation: {
      required: true,
      validEmail: true,
      maximumLength: 128
    }
  },
  buurt_van_werk_ook_aanvragen: {
    dataType: "object",
    validation: {
      required: true
    },
    validation_back_end: {
      isValidObject: true,
      idKey: "id",
      stringKey: "title",
      stringValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 64
      },
      idValue: {
        isInteger: true,
        minimum: 1,
        maximum: 2
      }
    }
  },
  elektrische_auto_bezit: {
    dataType: "object",
    validation: {
      required: true
    },
    validation_back_end: {
      isValidObject: true,
      idKey: "id",
      stringKey: "title",
      stringValue: {
        oneLineAllowedCharactersOnly: true,
        maximumLength: 64
      },
      idValue: {
        isInteger: true,
        minimum: 1,
        maximum: 2
      }
    }
  },
  datum_nieuwe_auto: {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      maximumLength: 128
    }
  },
  merk_type_auto: {
    dataType: "string",
    validation: {
      required: true,
      oneLineAllowedCharactersOnly: true,
      maximumLength: 128
    }
  },
  toestemming: {
    dataType: "boolean",
    validation: {
      required: true
    }
  }
};
