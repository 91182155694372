/**
 * Tenant Configurations
 * In this file we set ALL of the configurations related to tenants
 * the environment variable VUE_APP_CURRENT_TENANT decides which tenant
 * to bootstrap the application with.
 */

// Data OpCharge

import {
  modelOpCharge,
  formConfigOpCharge,
  gemeentesOpcharge,
  errorMessages,
  customValidationRuleDefinitions,
} from "./store/shared";

// Data GoRal

import { formConfigGoRal, modelGoRal, gemeentesGoRal } from "./store/shared";

import { MergeModelWithFormConfig } from "@evtools/accow-stateful-forms/stateful-forms";
import { testFormFileUpload } from "../data/forms/test-form-file-upload";

// View components

import ChargerRequestFormOpCharge from "./views/ChargerRequestFormOpCharge";
import ChargerRequestFormGoRal from "./views/ChargerRequestFormGoRal";
import DemoFormForTesting from "./views/DemoFormForTesting";

const currentTenant = process.env.VUE_APP_CURRENT_TENANT;

const tenants = [
  {
    tenantId: "op-charge",
    formConfig: formConfigOpCharge,
    model: modelOpCharge, // is optional
    component: ChargerRequestFormOpCharge,
    customErrorMessages: errorMessages.nl,
    customValidationRuleDefinitions: customValidationRuleDefinitions,
    tenantSpecificData: {
      gemeentes: gemeentesOpcharge,
    },
    themingDefaults: {
      theme: {
        themes: {
          light: {
            primary: "#b1c800",
            warning: "#ff9800",
            greyed: "#dcdccd",
          },
        },
      },
    },
  },

  {
    tenantId: "go-ral",
    formConfig: formConfigGoRal,
    model: modelGoRal,
    component: ChargerRequestFormGoRal,
    customErrorMessages: errorMessages.nl,
    customValidationRuleDefinitions: customValidationRuleDefinitions,
    tenantSpecificData: {
      gemeentes: gemeentesGoRal,
    },
    themingDefaults: {
      theme: {
        themes: {
          light: {
            primary: "#9E0330",
            warning: "#D796A9",
            greyed: "#dcdccd",
          },
        },
      },
    },
  },

  {
    tenantId: "test-tenant",
    // MergeModelWithFormConfig implementation for testing

    formConfig: testFormFileUpload,

    // model: null,

    component: DemoFormForTesting,
    tenantSpecificData: {
      gemeentes: gemeentesOpcharge,
    },
    themingDefaults: {
      theme: {
        themes: {
          light: {
            primary: "#10599f",
            warning: "#ff9800",
            greyed: "#dcdccd",
          },
        },
      },
    },
  },
];

const currentTenantConfig = tenants.filter(
  (tenant) => tenant.tenantId === currentTenant
)[0];

if (
  Object.hasOwnProperty.call(currentTenantConfig, "formConfig") &&
  Object.hasOwnProperty.call(currentTenantConfig, "model")
) {
  currentTenantConfig.formConfigMergedWithModel = new MergeModelWithFormConfig({
    formConfig: currentTenantConfig.formConfig,
    model: currentTenantConfig.model,
  });
} else {
  currentTenantConfig.formConfigMergedWithModel =
    currentTenantConfig.formConfig;
}

export { currentTenantConfig };
