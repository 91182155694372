module.exports.errorMessagesFR = {
  REQUIRED: "Ce champ est requis",
  MINIMAL_LENGTH: "le nombre minimum de caractères est",
  MAXIMUM_LENGTH: "le nombre maximum de caractères a été dépassé, le maximum est",
  MINIMAL_VALUE: "la valeur doit être au moins",
  MAXIMUM_VALUE: "la valeur doit être au maximum",
  INVALID_INTEGER: "La valeur n'est pas un entier",
  INVALID_FLOAT:
    "La valeur n'est pas un flottant ou un nombre valide, le nombre maximum de décimales autorisé est",
  IS_DUTCH_POSTAL_CODE: "ceci n'est pas un code postal néerlandais valide",
  IS_BELGIAN_POSTAL_CODE: "ceci n'est pas un code postal de Belgique valide",
  VALID_EMAIL: "Cette adresse email n'est pas valide",
  AGREED: "doit être vérifié",
  INVALID_DATA_TYPE: "un type de données invalide a été envoyé",
  VALID_PHONE_NUMBER: "Ce n'est pas un numéro de téléphone valide",
  ALLOWED_CHARACTERS_ONLY: "des caractères invalides ont été saisis",
  THE_FIELD: "le champ",
  NOT_AVAILABLE_IN_DATA_SET: "n'est pas présent dans les données fournies",
  CANNOT_BE_EMPTY: "ne doit pas être vide",
};
