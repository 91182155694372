<template>
  <v-container fluid>
    <logo-ev-tools />
    <v-container
      fluid
      class="pl-16 pr-16"
    >
      <h2 class="opc-title">
        EVTools Sandbox
      </h2>
      <v-container
        class="opc-header primary"
        fluid
      >
        <h3 class="opc-header-title">
          Testformulier
        </h3>
      </v-container>
      <asf-form-builder :form-config="formConfig" />
    </v-container>
  </v-container>
</template>

<script>
import LogoEvTools from '../components/logos/LogoEvTools'

export default {
  components: {
    LogoEvTools,
  },
  props: {
    formName: {
      type: String,
      default: 'demoFormOpCharge',
    },
  },
  computed: {
    formConfig() {
      return this.$store.state.AsfForms.forms[this.formName]
    },
  },
}
</script>

<style lang="scss">
.opc-title {
  font-weight: 300;
  font-size: 50px;
  line-height: 1.1;
  margin: 50px 0px;
}

.opc-header {
  padding: 30px 100px;
  color: #fff;
  margin-bottom: 30px;
}

.opc-header-title {
  font-size: 30px;
  font-weight: 300;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
