module.exports.formConfigGoRal = {
  title: 'Dit is een demo aanvraag formulier voor GoRal',
  formName: 'formConfigGoRal',
  allPanelsOpen: true,
  readOnly: false,
  submitButton: {
    title: 'Verstuur mijn aanvraag',
    validateTitle: 'Controleer uw invoer',
    useSettingsKeyAgreed: true,
    action: {
      storeAction: 'Project/sendChargerRequestData',
      actionArgument: 'formConfigGoRal',
    },
  },
  layout: {
    useGrid: false,
    gridDefinition: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },

  panels: [
    {
      id: 10,
      title: 'Uw gemeente',
      formItems: [
        {
          id: 10,
          map: 'gemcode',
          type: 'selectObject',
          title:
            'In welke gemeente bent u woonachtig of bent u een werkgever en wilt u een laadpaal aanvragen?',
          default: null,
          value: null,

          fetchItems: {
            getter: 'Project/gemeentes',
          },
          options: {
            hint: 'Kies een gemeente',
            itemText: 'name',
            itemValue: 'code',
          },
        },
        {
          id: 20,
          map: 'conditions.vehicle',
          type: 'radio',
          show: {
            value: false,
            multipleConditionsMode: 'allConditionsTrue',
            conditions: [
              {
                formItemPath: '10.10',
                operator: '!==',
                value: null,
              },
            ],
          },
          title:
            'Bent u (binnenkort) in het bezit van een elektrisch voertuig?',
          default: 1,
          value: 1,
          items: [
            {
              id: 1,
              title: 'ja',
            },
            {
              id: 2,
              title: 'nee',
            },
          ],
        },

        {
          id: 30,
          type: 'helpText',
          show: {
            value: false,
            multipleConditionsMode: 'allConditionsTrue',
            conditions: [
              {
                formItemPath: '10.20',
                operator: '===',
                value: 2,
              },
            ],
          },
          title:
            'U komt alleen in aanmerking voor een publieke laadpaal in deze gemeente indien u (binnenkort) in bezit bent van een elektrisch voertuig.',
          color: 'red',
          icon: null,
          outlined: true,
        },

        {
          id: 40,
          map: 'conditions.parkingspace',
          type: 'radio',
          show: {
            value: false,
            multipleConditionsMode: 'allConditionsTrue',
            conditions: [
              {
                formItemPath: '10.20',
                operator: '===',
                value: 1,
              },
            ],
          },
          title:
            'Hebt u een eigen (bedrijfs)parkeerterrein? Daaronder valt een garage, een oprit of een parkeerplek in de garage behorend bij uw appartement',
          default: 2,
          value: 2,
          items: [
            {
              id: 1,
              title: 'ja',
              show: {
                value: true,
              },
            },
            {
              id: 2,
              title: 'nee',
              show: {
                value: true,
              },
            },
          ],
        },

        {
          id: 50,
          type: 'helpText',
          show: {
            value: false,
            multipleConditionsMode: 'allConditionsTrue',
            conditions: [
              {
                formItemPath: '10.40',
                operator: '===',
                value: 1,
              },
            ],
          },
          title:
            'U komt alleen in aanmerking voor een publieke laadpaal in deze gemeente indien u geen (mogelijkheid tot een) eigen parkeergelegenheid heeft.',
          color: 'red',
          icon: null,
          outlined: true,
        },
      ],
    },

    // Vehicle information
    {
      id: 20,
      title: 'Voor welke voertuig wilt u een laadpaal aanvragen?',
      show: {
        value: false,
        multipleConditionsMode: 'allConditionsTrue',
        conditions: [
          {
            formItemPath: '10.40',
            operator: '===',
            value: 2,
          },
        ],
      },
      layout: {
        useGrid: true,
        gridDefinition: {
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridTemplateRows: '1fr auto',
          gridColumnGap: '24px',
          gridRowGap: '5px',
        },
      },
      formItems: [
        {
          id: 1,
          type: 'textField',
          title: 'Merk',
          map: 'vehicle.brand',
          default: '',
          value: '',
          layout: {
            gridArea: '1/1/2/2',
          },
        },
        {
          id: 2,
          type: 'textField',
          title: 'Model',
          map: 'vehicle.model',
          default: '',
          value: '',
          layout: {
            gridArea: '1/2/2/3',
          },
        },
        {
          id: 3,
          type: 'helpTextHtml',
          title:
            '<p><b>Dit hebben we nog van u nodig</b></p>' +
            '<p>Voor we met uw aanvraag aan de slag gaan, moeten we weten dat uw auto bij het aanvraagadres hoort. U kunt dat bewijzen met een kopie van het koop- of leasecontract van uw auto, met de berijdersverklaring en/of met een kentekenbewijs.</p>' +
            '<p>Welk van de drie documenten u ook kiest, de volgende 3 dingen moeten erop staan: uw naam (1), uw aanvraagadres (2) en uw type auto of het kenteken (3). Staan alle drie deze gegevens op een document? Dan hoeft u alleen dat document in het eerste uploadveld te uploaden. Staan de gegevens verspreid over meerdere documenten? Gebruik dan ook de andere uploadvelden. Daar kunt u dan een of twee extra documenten toevoegen.</p>' + '<p>Vattenfall moet op basis van de documenten alleen uw naam, adres en type elektrische auto kunnen herleiden. Zorg ervoor dat andere persoonsgegevens zoals BSN en IBAN niet op de documenten staan of dat u deze heeft afgeschermd/weggelakt.</p>',
          layout: {
            gridArea: '2/1/3/3',
          },
        },
        {
          id: 4,
          map: 'vehicle.upload',
          type: 'evToolsSingleFileUpload',
          title: 'Aankoopbewijs / leasecontract / opdrachtbevestiging',
          default: [],
          value: [],
          layout: {
            gridArea: '3/1/4/3',
          },
          options: {
            doctype: 'vehicle',
            storeAction: 'Project/handleAwsFileUpload',
            acceptedFileTypes: 'image/jpg, image/jpeg, image/png, .pdf',
          },
        },
        {
          id: 5,
          map: 'vehicle.upload.2',
          type: 'evToolsSingleFileUpload',
          title: 'Tweede document (optioneel)',
          default: [],
          value: [],
          layout: {
            gridArea: '4/1/5/3',
          },
          options: {
            doctype: 'vehicle',
            storeAction: 'Project/handleAwsFileUpload',
            acceptedFileTypes: 'image/jpg, image/jpeg, image/png, .pdf',
          },
        },
        {
          id: 6,
          map: 'vehicle.upload.3',
          type: 'evToolsSingleFileUpload',
          title: 'Derde document (optioneel)',
          default: [],
          value: [],
          layout: {
            gridArea: '5/1/6/3',
          },
          options: {
            doctype: 'vehicle',
            storeAction: 'Project/handleAwsFileUpload',
            acceptedFileTypes: 'image/jpg, image/jpeg, image/png, .pdf',
          },
        },
      ],
    },

    // Parkingspot information
    {
      id: 30,
      title: 'Parkeervergunning',
      show: {
        value: false,
        multipleConditionsMode: 'allConditionsTrue',
        conditions: [
          {
            formItemPath: '20.2',
            operator: '!==',
            value: '',
          },
        ],
      },
      formItems: [
        {
          id: 1,
          map: 'parkingspot.permit',
          type: 'radio',
          title:
            'Beschikt u over een parkeervergunning op het kenteken waarvoor u een laadpaal wilt aanvragen?',
          default: null,
          value: null,
          items: [
            {
              id: 1,
              title: 'ja',
            },
            {
              id: 2,
              title: 'nee',
            },
          ],
        },
        {
          id: 2,
          map: 'parkingspot.upload',
          type: 'evToolsSingleFileUpload',

          title: 'Upload hier een kopie van de parkeervergunning',
          default: [],
          value: [],
          options: {
            doctype: 'parkingspot',
            storeAction: 'Project/handleAwsFileUpload',
            acceptedFileTypes: 'image/jpg, image/jpeg, image/png, .pdf',
          },
          show: {
            value: false,
            multipleConditionsMode: 'allConditionsTrue',
            conditions: [
              {
                formItemPath: '30.1',
                operator: '===',
                value: 1,
              },
            ],
          },
        },
        {
          id: 3,
          map: 'parkingspot.reservedspot',
          type: 'radio',
          title:
            'Bent u in bezit van een parkeerplek op kenteken in de openbare ruimte? En is dit het kenteken waarop u de laadpaal aanvraag doet?',
          default: null,
          value: null,
          items: [
            {
              id: 1,
              title: 'ja',
            },
            {
              id: 2,
              title: 'nee',
            },
          ],
        },
      ],
    },

    // Desired location
    {
      id: 40,
      title: 'Vul hier uw woon- of werkadres in',
      show: {
        value: false,
        multipleConditionsMode: 'allConditionsTrue',
        conditions: [
          {
            formItemPath: '30.3',
            operator: '!==',
            value: null,
          },
        ],
      },
      layout: {
        useGrid: true,
        gridDefinition: {
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridTemplateRows: 'repeat(3, auto)',
          gridColumnGap: '24px',
          gridRowGap: '0px',
        },
      },
      formItems: [
        {
          id: 1,
          type: 'helpText',
          title: 'Gebruik hiervoor het adres van uw parkeerplaats op kenteken.',
          color: 'blue',
          icon: null,
          outlined: true,
          layout: {
            gridArea: '1/1/2/5',
          },
          show: {
            value: false,
            multipleConditionsMode: 'allConditionsTrue',
            conditions: [
              {
                formItemPath: '30.3',
                operator: '===',
                value: 1,
              },
            ],
          },
        },
        {
          id: 2,
          type: 'helpText',
          title:
            'Het gewenste adres dat u hier opgeeft wordt gebruikt om een geschikte locatie voor het oplaadpunt in de omgeving te zoeken.',
          color: 'blue',
          icon: null,
          outlined: true,
          layout: {
            gridArea: '1/1/2/5',
          },
          show: {
            value: false,
            multipleConditionsMode: 'allConditionsTrue',
            conditions: [
              {
                formItemPath: '30.3',
                operator: '===',
                value: 2,
              },
            ],
          },
        },
        {
          id: 3,
          type: 'textField',
          map: 'location.street',
          title: 'Straat',
          default: '',
          value: '',
          layout: {
            gridArea: '2/1/3/3',
          },
        },
        {
          id: 4,
          type: 'textField',
          title: 'Huisnummer',
          map: 'location.number',
          default: '',
          value: '',
          layout: {
            gridArea: '2/3/3/4',
          },
        },
        {
          id: 5,
          type: 'textField',
          title: 'Huisnummertoevoeging',
          map: 'location.suffix',
          default: '',
          value: '',
          layout: {
            gridArea: '2/4/3/5',
          },
        },
        {
          id: 6,
          type: 'textField',
          title: 'Postcode',
          map: 'location.postcode',
          default: '',
          value: '',
          layout: {
            gridArea: '3/1/4/2',
          },
        },
        {
          id: 7,
          type: 'textField',
          title: 'Plaats',
          map: 'location.city',
          default: '',
          value: '',
          layout: {
            gridArea: '3/2/4/5',
          },
        },
      ],
    },

    {
      id: 50,
      title:
        'In welk tijdsvak verwacht u het meest gebruik te maken van de laadpaal?',
      show: {
        value: false,
        multipleConditionsMode: 'allConditionsTrue',
        conditions: [
          {
            formItemPath: '30.3',
            operator: '!==',
            value: null,
          },
        ],
      },
      formItems: [
        {
          id: 10,
          map: "context.chargingtime",
          type: 'evToolsMultiCheckbox',
          title: 'Maak één of meerdere keuzes:',
          default: null,
          value: null,
          items: [
            { id: "night",title: 'tussen 0:00 en 07:00', value: false },
            { id: "morning", title: 'tussen 07:00 en 12:00', value: false },
            { id: "afternoon", title: 'tussen 12:00 en 17:00', value: false },
            { id: "evening", title: 'tussen 17:00 en 24:00', value: false },
          ],
        },
        {
          id: 20,
          map: 'context.remarks',
          type: 'textArea',
          title: 'Heeft u nog aanvullende opmerkingen?',
          default: '',
          value: '',
        },
      ],
    },

    // Contact information
    {
      id: 60,
      title: 'Contact informatie',
      show: {
        value: false,
        multipleConditionsMode: 'allConditionsTrue',
        conditions: [
          {
            formItemPath: '30.3',
            operator: '!==',
            value: null,
          },
        ],
      },
      layout: {
        useGrid: true,
        gridDefinition: {
          gridTemplateColumns: 'repeat(2, 1fr)',
          gridTemplateRows: 'repeat(3, auto)',
          gridColumnGap: '24px',
          gridRowGap: '0px',
        },
      },
      formItems: [
        {
          id: 1,
          type: 'helpText',
          title:
            'Uw contactinformatie wordt uitsluitend gebruikt om u te informeren over de status van uw aanvraag. Lees voor meer informatie ons privacy statement via deze -link-',
          color: 'blue',
          icon: null,
          outlined: true,
          layout: {
            gridArea: '1/1/2/3',
          },
        },
        {
          id: 2,
          type: 'textField',
          map: 'contact.initials',
          title: 'Initialen',
          default: '',
          value: '',
          layout: {
            gridArea: '2/1/3/2',
          },
        },
        {
          id: 3,
          type: 'textField',
          title: 'Achternaam',
          map: 'contact.surname',
          default: '',
          value: '',
          layout: {
            gridArea: '2/2/3/3',
          },
        },
        {
          id: 4,
          type: 'textField',
          title: 'Telefoonnummer',
          map: 'contact.phone',
          default: '',
          value: '',
          layout: {
            gridArea: '3/1/4/2',
          },
        },
        {
          id: 5,
          type: 'textField',
          title: 'E-mailadres',
          map: 'contact.email',
          default: null,
          value: null,
          layout: {
            gridArea: '3/2/4/3',
          },
        },
      ],
    },
    {
      id: 70,
      title: 'Toestemming',
      show: {
        value: false,
        multipleConditionsMode: 'allConditionsTrue',
        conditions: [
          {
            formItemPath: '30.3',
            operator: '!==',
            value: null,
          },
        ],
      },
      formItems: [
        {
          id: 10,
          type: 'checkbox',
          map: 'toestemming',
          title:
            'Met het verzenden van deze aanvraag ga ik akkoord met het gebruik van mijn contactgegevens voor de hierboven aangevinkte doelen.',
          default: false,
          value: false,
          validation: {
            required: true,
          },
          options: {
            settingsKey: 'agreed',
          },
        },
      ],
    },
  ],
}
