// deprecated
const testFormFileUpload = {
  title: 'Test Form voor het testen van een file upload compopent',
  formName: 'testFormFileUpload',
  allPanelsOpen: true,
  readOnly: false,
  submitButton: {
    title: 'Verstuur mijn aanvraag',
    action: {
      storeAction: 'Project/sendChargerRequestData',
      actionArgument: 'formDataOpCharge',
    },
  },
  panels: [
    {
      id: 10,
      title: 'U wilt een file uploaden?',
      formItems: [
        {
          id: 20,
          type: 'evToolsFileUpload',
          title: 'Upload hier de foto\'s van de verkeerssituatie',
          options: {
            doctype: 'parkeerVergunning',
            storeAction: 'Project/handleAwsFileUpload',
          },
          default: [],
          value: [],
        },
        // {
        //   id: 20,
        //   type: "evToolsFileUpload",
        //   title: "Select a folder to upload here:",
        //   default: [],
        //   value: [],
        //   options: {
        //     folder: true,
        //   },
        // },
      ],
    },
  ],
}

export { testFormFileUpload }
