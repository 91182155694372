module.exports.formConfigOpCharge = {
  title: "Dit is een demo aanvraag formulier voor OpCharge",
  formName: "formConfigOpCharge",
  allPanelsOpen: true,
  readOnly: false,
  // useExpansionPanels: true,
  submitButton: {
    title: "Verstuur mijn aanvraag",
    validateTitle: "Controleer uw invoer",
    action: {
      storeAction: "Project/sendChargerRequestData",
      actionArgument: "formConfigOpCharge"
    }
  },
  layout: {
    useGrid: false,
    gridDefinition: {
      gridTemplateColumns: "repeat(2, 1fr)"
    }
  },
  panels: [
    {
      id: 10,
      // Test
      title: "U wilt een laadpaal aanvragen:",
      formItems: [
        {
          id: 10,
          type: "radio",
          map: "locatie",
          default: 1,
          value: 1,
          items: [
            {
              id: 1,
              title: "In de buurt van mijn thuislocatie",
              show: {
                value: true
              }
            },
            {
              id: 2,
              title: "Elders",
              show: {
                value: true
              }
            }
          ]
        }
      ]
    },
    {
      id: 20,
      title: "Vul hier het gewenste adres voor de laadpaal in:",
      show: {
        value: false,
        multipleConditionsMode: "allConditionsTrue",
        conditions: [
          {
            formItemPath: "10.10",
            operator: "===",
            value: 2
          }
        ]
      },
      layout: {
        useGrid: true,
        gridDefinition: {
          gridTemplateColumns: "repeat(12, 1fr)",
          gridTemplateRows: "repeat(3, 1fr)",
          gridColumnGap: "24px",
          gridRowGap: "5px"
        }
      },
      formItems: [
        {
          id: 10,
          type: "textField",
          title: "Straat",
          map: "wens_straat",
          default: "",
          value: "",
          layout: {
            gridArea: "1/1/2/8"
          }
        },
        {
          id: 20,
          type: "textField",
          title: "Huisnummer",
          map: "wens_nummer",
          default: "",
          value: "",
          layout: {
            gridArea: "1/8/2/10"
          }
        },
        {
          id: 30,
          type: "textField",
          title: "Toevoeging",
          map: "wens_toevoeging",
          default: "",
          value: "",
          layout: {
            gridArea: "1/10/2/12"
          }
        },
        {
          id: 40,
          type: "textField",
          title: "Postcode",
          map: "wens_postcode",
          default: "",
          value: "",
          layout: {
            gridArea: "2/1/3/7"
          }
        },
        {
          id: 50,
          type: "textField",
          title: "Plaats",
          map: "wens_plaats",
          default: "",
          value: "",
          layout: {
            gridArea: "2/7/3/12"
          }
        },
        {
          id: 60,
          type: "selectObject",
          title: "Gemeente",
          map: "wens_gemeente",
          default: null,
          value: null,
          fetchItems: {
            getter: "Project/gemeentes"
          },
          options: {
            hint: "Kies een gemeente",
            itemText: "name",
            itemValue: "code"
          },
          layout: {
            gridArea: "3/1/4/7"
          }
        }
      ]
    },
    {
      id: 30,
      title: "Uw gegevens en thuisadres:",
      formItems: [
        {
          id: 1,
          type: "radio",
          map: "aanhef",
          default: 1,
          value: 1,
          items: [
            {
              id: 1,
              title: "De heer"
            },
            {
              id: 2,
              title: "Mevrouw"
            }
          ]
        }
      ]
    },
    {
      id: 40,
      title: null,
      layout: {
        useGrid: true,
        gridDefinition: {
          gridTemplateColumns: "repeat(12, 1fr)",
          gridTemplateRows: "repeat(5, 1fr)",
          gridColumnGap: "24px",
          gridRowGap: "5px"
        }
      },
      formItems: [
        // {
        //   id: 1,
        //   type: "numericField",
        //   title: "testField",
        //   map: "testInteger",
        //   default: null,
        //   value: null,
        //   required: true,
        //   layout: {
        //     gridArea: "1/1/2/6"
        //   }
        // },
        {
          id: 10,
          type: "textField",
          title: "Voornaam",
          map: "voornaam",
          default: null,
          value: null,
          required: true,
          layout: {
            gridArea: "1/1/2/6"
          }
        },
        {
          id: 20,
          type: "textField",
          title: "Achternaam",
          map: "achternaam",
          default: "",
          value: "",
          layout: {
            gridArea: "1/6/2/12"
          }
        },
        {
          id: 30,
          type: "textField",
          title: "Straat",
          map: "straat",
          default: "",
          value: "",
          layout: {
            gridArea: "2/1/3/8"
          }
        },
        {
          id: 40,
          type: "textField",
          title: "Huisnummer",
          map: "nummer",
          default: "",
          value: "",
          layout: {
            gridArea: "2/8/3/10"
          }
        },
        {
          id: 50,
          type: "textField",
          title: "Toevoeging",
          map: "toevoeging",
          default: "",
          value: "",
          layout: {
            gridArea: "2/10/3/12"
          }
        },
        {
          id: 60,
          type: "textField",
          title: "Postcode",
          map: "postcode",
          default: "",
          value: "",
          layout: {
            gridArea: "3/1/4/7"
          }
        },
        {
          id: 70,
          type: "textField",
          title: "Plaats",
          map: "plaats",
          default: "",
          value: "",
          layout: {
            gridArea: "3/7/4/12"
          }
        },
        {
          id: 80,
          type: "selectObject",
          title: "Gemeente",
          map: "gemeente",
          default: null,
          value: null,
          fetchItems: {
            getter: "Project/gemeentes"
          },
          options: {
            hint: "Kies een gemeente",
            itemText: "name",
            itemValue: "code"
          },
          layout: {
            gridArea: "4/1/5/7"
          }
        },
        {
          id: 90,
          type: "textField",
          title: "E-mailadres",
          map: "email",
          default: "",
          value: "",
          layout: {
            gridArea: "5/1/6/7"
          }
        },
        {
          id: 100,
          type: "textField",
          title: "Telefoonnummer",
          map: "telefoonnummer",
          default: "",
          value: "",
          layout: {
            gridArea: "5/7/6/12"
          }
        },
      ]
    },
    {
      id: 50,
      title: "Wilt u ook een laadpaal aanvragen in de buurt van uw werk?",
      formItems: [
        {
          id: 10,
          type: "radio",
          default: 1,
          value: 1,
          map: "buurt_van_werk_ook_aanvragen",
          items: [
            {
              id: 1,
              title: "Ja (wij nemen contact met u op voor meer informatie)",
              show: {
                value: true
              }
            },
            {
              id: 2,
              title: "Nee",
              show: {
                value: true
              }
            }
          ]
        }
      ]
    },
    {
      id: 60,
      title: "Elektrische auto?",
      formItems: [
        {
          id: 10,
          type: "radio",
          default: 1,
          value: 1,
          map: "elektrische_auto_bezit",
          items: [
            {
              id: 1,
              title: "Ik heb al een elektrische auto"
            },
            {
              id: 2,
              title: "Ik heb binnenkort een elektrische auto"
            }
          ]
        }
      ]
    },
    {
      id: 70,
      title: "Wanneer verwacht u de elektrische auto?",
      show: {
        value: false,
        multipleConditionsMode: "allConditionsTrue",
        conditions: [
          {
            formItemPath: "60.10",
            operator: "===",
            value: 2
          }
        ]
      },
      formItems: [
        {
          id: 10,
          map: "datum_nieuwe_auto",
          type: "dateField",
          default: "",
          value: ""
        }
      ]
    },
    {
      id: 80,
      title: "Wat is het merk en type auto waarvoor u de laadpaal aanvraagt?",
      formItems: [
        {
          id: 10,
          map: "merk_type_auto",
          type: "textField",
          title: "merk & type auto",
          default: "",
          value: ""
        }
      ]
    },
    {
      id: 90,
      title: "Toestemming",
      formItems: [
        {
          id: 10,
          type: "checkbox",
          map: "toestemming",
          title:
            "Met het verzenden van deze aanvraag ga ik akkoord met het gebruik van mijn contactgegevens voor de hierboven aangevinkte doelen.",
          default: false,
          value: false
        }
      ]
    }
  ]
};
