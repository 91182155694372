<template>
  <charger-request-form
    :form-name="formName"
    title="Aanvraag publieke laadpaal"
    beam-title="Nederland"
    document-title="OPCHARGE Aanvraag publieke paal"
    favicon-file-name="opcharge-favicon.ico"
  >
    <template #logo>
      <logo-op-charge />
    </template>
    <template #dialogLogo>
      <logo-op-charge />
    </template>
  </charger-request-form>
</template>

<script>
import LogoOpCharge from '../components/logos/LogoOpCharge'
import ChargerRequestForm from '../components/ChargerRequestForm'

export default {
  components: {
    LogoOpCharge,
    ChargerRequestForm,
  },
  props: {
    formName: {
      type: String,
      default: 'formConfigOpCharge',
    },
  },
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables";

.op-charge {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
  }
}
</style>
