module.exports.gemeentesGoRal = [
  {
    code: "0197",
    name: "Aalten",
  },
  {
    code: "0141",
    name: "Almelo",
  },
  {
    code: "0200",
    name: "Apeldoorn",
  },
  {
    code: "0202",
    name: "Arnhem",
  },
  {
    code: "0203",
    name: "Barneveld",
  },
  {
    code: "1945",
    name: "Berg en Dal",
  },
  { code: "1859", name: "Berkelland" },
  {
    code: "0209",
    name: "Beuningen",
  },
  {
    code: "0147",
    name: "Borne",
  },
  {
    code: "1876",
    name: "Bronckhorst",
  },
  {
    code: "0213",
    name: "Brummen",
  },
  {
    code: "0214",
    name: "Buren",
  },
  {
    code: "0148",
    name: "Dalfsen",
  },
  {
    code: "0150",
    name: "Deventer",
  },
  {
    code: "1774",
    name: "Dinkelland",
  },
  {
    code: "1509",
    name: "Oude IJsselstreek",
  },
  {
    code: "0221",
    name: "Doesburg",
  },
  {
    code: "0222",
    name: "Doetinchem",
  },
  {
    code: "0225",
    name: "Druten",
  },
  {
    code: "0226",
    name: "Duiven",
  },
  {
    code: "0228",
    name: "Ede",
  },
  {
    code: "0230",
    name: "Elburg",
  },
  {
    code: "0153",
    name: "Enschede",
  },
  {
    code: "0232",
    name: "Epe",
  },
  {
    code: "0233",
    name: "Ermelo",
  },
  {
    code: "0158",
    name: "Haaksbergen",
  },
  {
    code: "0160",
    name: "Hardenberg",
  },
  {
    code: "0244",
    name: "Hattem",
  },
  {
    code: "0246",
    name: "Heerde",
  },
  {
    code: "0163",
    name: "Hellendoorn",
  },
  {
    code: "0164",
    name: "Hengelo",
  },
  { code: "0252", name: "Heumen" },
  {
    code: "1735",
    name: "Hof van Twente",
  },
  {
    code: "0166",
    name: "Kampen",
  },
  {
    code: "1705",
    name: "Lingewaard",
  },
  {
    code: "0262",
    name: "Lochem",
  },
  {
    code: "0168",
    name: "Losser",
  },
  {
    code: "0263",
    name: "Maasdriel",
  },
  {
    code: "1955",
    name: "Montferland",
  },
  {
    code: "1740",
    name: "Neder-betuwe",
  },
  {
    code: "0267",
    name: "Nijkerk",
  },
  {
    code: "0268",
    name: "Nijmegen",
  },
  {
    code: "0302",
    name: "Nunspeet",
  },
  {
    code: "0269",
    name: "Oldebroek",
  },
  {
    code: "0173",
    name: "Oldenzaal",
  },
  {
    code: "1773",
    name: "Olst-Wijhe",
  },
  {
    code: "0175",
    name: "Ommen",
  },
  {
    code: "1586",
    name: "Oost Gelre",
  },
  {
    code: "1734",
    name: "Overbetuwe",
  },
  { code: "0273", name: "Putten" },
  {
    code: "0177",
    name: "Raalte",
  },
  {
    code: "0275",
    name: "Rheden",
  },
  {
    code: "1742",
    name: "Rijssen-Holten",
  },
  {
    code: "0277",
    name: "Rozendaal",
  },
  {
    code: "0180",
    name: "Staphorst",
  },
  {
    code: "1708",
    name: "Steenwijkerland",
  },
  {
    code: "0281",
    name: "Tiel",
  },
  {
    code: "0183",
    name: "Tubbergen",
  },
  {
    code: "1700",
    name: "Twenterand",
  },
  {
    code: "0345",
    name: "Veenendaal",
  },
  {
    code: "0285",
    name: "Voorst",
  },
  {
    code: "0289",
    name: "Wageningen",
  },
  {
    code: "1960",
    name: "West Betuwe",
  },
  {
    code: "0668",
    name: "West Maas en Waal",
  },
  {
    code: "0293",
    name: "Westervoort",
  },
  {
    code: "0189",
    name: "Wierden",
  },
  {
    code: "0296",
    name: "Wijchen",
  },
  {
    code: "0294",
    name: "Winterswijk",
  },
  {
    code: "0299",
    name: "Zevenaar",
  },
  {
    code: "0301",
    name: "Zutphen",
  },
  {
    code: "1896",
    name: "Zwartewaterland",
  },
  {
    code: "0193",
    name: "Zwolle",
  },
];
