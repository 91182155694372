module.exports.errorMessagesNL = {
  REQUIRED: "dit veld is verplicht",
  MINIMAL_LENGTH: "het minimum aantal karakters is",
  MAXIMUM_LENGTH: "het maximale aantal karakters is overschreden, maximum is",
  MINIMAL_VALUE: "de waarde moet zijn minimaal",
  MAXIMUM_VALUE: "de waarde mag maximaal zijn",
  INVALID_INTEGER: "Waarde is geen geheel getal",
  INVALID_FLOAT:
    "Waarde is geen geldig float of getal, het maximum aantal toegestane decimalen is",
  IS_DUTCH_POSTAL_CODE: "dit is geen geldige Nederlandse Postcode",
  IS_BELGIAN_POSTAL_CODE: "dit is geen geldige Belgische Postcode",
  VALID_EMAIL: "dit is geen geldig emailadres",
  AGREED: "moet aangevinkt zijn",
  INVALID_DATA_TYPE: "er is een ongeldig datatype verstuurd",
  VALID_PHONE_NUMBER: "dit is geen geldig telefoonnummer",
  ALLOWED_CHARACTERS_ONLY: "er zijn ongeldige characters ingevoerd",
  THE_FIELD: "het veld",
  NOT_AVAILABLE_IN_DATA_SET: "is niet aanwezig in de aangeleverde data",
  CANNOT_BE_EMPTY: "mag niet leeg zijn",
  INVALID_UUID_V4: "the provided uuid is invalid",
  MINIMAL_NUMBER_OF_CHECKED: 'Minimaal hierboven aan te kruisen:',
  CHOICES: 'keuze optie(s)'
};
